@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap');

@font-face {
  font-family: 'cacheHeader';
  src: local('BuroBold'), url(./assets/BuroBold.woff) format('woff');
}

p {
  font-family: 'cacheHeader', sans-serif;
  font-size: 1rem;
  text-transform: uppercase;
}

a {
  color: black !important;
}

.cache-arial-header {
  font-family: "Arial Black", "Arial Bold", sans-serif !important;
  line-height: 0.34;
}

.h1, .h2, .h3, h4, .h5, .h6, .btn {
  font-family: 'cacheHeader', sans-serif;
  text-transform: uppercase;
}

.fs-7 {
  font-size: 1rem !important;
}

.fs-8 {
  font-size: 0.75rem !important;
}

.fs-foot {
  font-size: 0.75rem !important;
}

.head-mw {
  max-width: 1200px;
}

.dropdown-item {
  font-size: 0.75rem;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff !important;
  background-color: black !important;
}

.dropdown-menu {
  border-radius: 0 !important;
  border: 1px solid black !important;
  padding: 0 !important;
}

.custom-nav-link{
  align-self: flex-end !important;
  text-align: end !important;
}

.custom-disabled {
  color: black !important;
}

.auction-card {
  border: 0 !important;
  width: 94vw;
}

.custom-head-auction {
  min-height: 200px;
}

.button-link {
  background-color: #fff !important;
  color:black !important;
  border: 0 !important;
  text-decoration: underline !important;
  vertical-align: top !important;
  width: auto !important;
}

.py-nav {
  padding-top: 0.5rem !important;
  padding-bottom: 0.2rem !important;
}

.cache-font {
  font-family: 'cacheHeader', sans-serif !important;
  text-transform: uppercase;
  color: black;
}

a:hover {
  color:rgba(0, 0, 0, 0.8) !important;
}


.cache-haptik {
  font-family: 'Arial';
  font-size: 1rem;
  text-transform: none;
  color: black !important;
}

.cache-nav {
  border-bottom: 2px solid #323232;
  font-size: 1rem;
  min-height: 35px !important;
}


.navbar-light .navbar-toggler {
  border: 0 !important;
}

.navbar-toggler:focus {
  box-shadow: none !important;
  color: black !important;
}

#basic-nav-dropdown {
  padding-top: 0.5rem;
  padding-bottom: 0.2rem;
}

.mix-vid {
  width: 100%;
}

.card-body {
  padding: 0.5rem !important;
}

.card-title {
  margin-bottom: 0.1rem !important;
  font-size: 1rem !important;
}

.share-btn {
  height: 3rem;
}

.navbar-light .navbar-nav .nav-link {
  color: black !important;
}

.navbar-light .nav-link:hover{
  background-color: rgba(0, 0, 0, 0.1);
}

.modal-content {
  border-radius: 0px !important;
}

.img-full-wide {
  width: 100vw !important;
}

.custom-spinner{
  position: absolute;
  margin: auto;
  top:0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 10rem !important;
  height: 10rem !important;
}

@media (min-width: 992px){
  .modal-lg, .modal-xl {
    max-width: 925px !important;
  }
}

@media (min-width: 1360px){
  .auction-card {
    width: 25rem;
  }
}

@media (min-width: 1147px) and (max-width: 1360px){
  .auction-card {
    width: 20.5rem;
  }
}


@media (min-width: 992px) and (max-width: 1147px){
  .auction-card {
    width: 17.3rem;
  }
}

@media (max-width: 992px){
  .h5 {
    font-size: 1rem !important;
  }

  .dropdown-item {
    font-size: 1rem;
  }

  .fs-8 {
    font-size: 1rem !important;
  }

  .navbar-light .nav-link:hover {
    background-color: rgba(0, 0, 0, 0) !important;
  }

  .dropdown-menu {
    border: none !important;
    background-color: rgba(0, 0, 0, 0) !important;
  }

}